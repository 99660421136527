import '../scss/block.scss'
import {registerBlockType} from "@wordpress/blocks";
import {InnerBlocks, InspectorControls, useBlockProps} from "@wordpress/block-editor";
import {
    Button,
    CheckboxControl,
    ColorPalette,
    Dashicon,
    Icon,
    PanelBody,
    PanelRow,
    SelectControl,
    TextControl
} from "@wordpress/components";
import {__} from '@wordpress/i18n'
import {SpaceVisualization} from "./space-visualization.js";
import {Fragment} from "react";

const ButtonList = ({buttons, value, onChange}) => {
    return (<PanelRow className={"responsive-buttons"}>
        {buttons.map(button => {
            const classNames = ['components-button', button.value === value ? 'active' : null].join(' ').trim()

            return (<button type={"button"} area-label={button.tooltip} className={classNames} onClick={() => onChange(button.value)}>
                <Icon icon={button.icon}/>
            </button>)
        })}
    </PanelRow>)
}

const ResponsiveButtonList = ({value, onChange}) => {
    const buttons = [
        { value: 'desktop', icon: 'desktop', tooltip: __('Desktop', 'sfat-spacer') },
        { value: 'laptop', icon: 'laptop', tooltip: __('Laptop', 'sfat-spacer') },
        { value: 'tablet', icon: 'tablet', tooltip: __('Tablet', 'sfat-spacer') },
        { value: 'smartphone', icon: 'smartphone', tooltip: __('Smartphone', 'sfat-spacer') }
    ]

    return <ButtonList
        buttons={buttons}
        value={value}
        onChange={key => onChange(key)}/>
}

registerBlockType('sfat-spacer/spacer-block', {
    version: 2,

    attributes: {
        responsive: {
            type: 'boolean',
            default: false
        },
        screensize: {
            type: 'string',
            default: 'desktop'
        },
        dim: {
            type: 'object',
            default: {
                desktop: {
                    margin: { t: 0, r: 0, b: 0, l: 0 },
                    border: { t: 1, r: 1, b: 1, l: 1 },
                    padding: { t: 20, r: 20, b: 20, l: 20 }
                },
                laptop: {
                    margin: { t: 0, r: 0, b: 0, l: 0 },
                    border: { t: 1, r: 1, b: 1, l: 1 },
                    padding: { t: 20, r: 20, b: 20, l: 20 }
                },
                tablet: {
                    margin: { t: 0, r: 0, b: 0, l: 0 },
                    border: { t: 1, r: 1, b: 1, l: 1 },
                    padding: { t: 20, r: 20, b: 20, l: 20 }
                },
                smartphone: {
                    margin: { t: 0, r: 0, b: 0, l: 0 },
                    border: { t: 1, r: 1, b: 1, l: 1 },
                    padding: { t: 20, r: 20, b: 20, l: 20 }
                },
            }
        },
        equal: {
            type: 'object',
            default: {
                desktop: {margin: true, border: true, padding: true},
                laptop: {margin: true, border: true, padding: true},
                tablet: {margin: true, border: true, padding: true},
                smartphone: {margin: true, border: true, padding: true}
            }
        },
        border: {
            type: 'object',
            default: {
                desktop: {style: 'solid', color: '#000'},
                laptop: {style: 'solid', color: '#000'},
                tablet: {style: 'solid', color: '#000'},
                smartphone: {style: 'solid', color: '#000'}
            }
        },
        maxWidth: {
            type: 'object',
            default: {
                enabled: false,
                value: 500,
                unit: '%',
                centered: true
            }
        }
    },

    edit({attributes, setAttributes}) {
        // next lines to help i18n find the strings
        __('margin', 'sfat-spacer')
        __('border', 'sfat-spacer')
        __('padding', 'sfat-spacer')

        const blockProps = useBlockProps()

        const {responsive, screensize, dim, equal, border, maxWidth} = attributes

        blockProps.style.margin = [
            dim[screensize].margin.t + 'px',
            maxWidth.enabled && maxWidth.centered ? 'auto' : dim[screensize].margin.r + 'px',
            dim[screensize].margin.b + 'px',
            maxWidth.enabled && maxWidth.centered ? 'auto' : dim[screensize].margin.l + 'px',
        ].join(' ')

        blockProps.style.padding = [
            dim[screensize].padding.t + 'px',
            dim[screensize].padding.r + 'px',
            dim[screensize].padding.b + 'px',
            dim[screensize].padding.l + 'px',
        ].join(' ')

        blockProps.style.borderWidth = [
            dim[screensize].border.t + 'px',
            dim[screensize].border.r + 'px',
            dim[screensize].border.b + 'px',
            dim[screensize].border.l + 'px',
        ].join(' ')

        blockProps.style.borderStyle = border[screensize].style
        blockProps.style.borderColor = border[screensize].color

        if (maxWidth.enabled) {
            blockProps.style.maxWidth = maxWidth.value + maxWidth.unit
        }

        const setDimension = (key, pos, val) => {
            const dimensions = {...dim}

            if ( ! responsive) {
                for (let size in dimensions) {
                    dimensions[size][key][pos] = parseInt(val)
                }
            } else {
                dimensions[screensize][key][pos] = parseInt(val)
            }

            setAttributes({dim: dimensions})
        }

        const setDimensionsEqual = (key, val) => {
            const dimensions = {...dim}

            if ( ! responsive) {
                for (let size in dimensions) {
                    for (let pos in dimensions[size][key]) {
                        dimensions[size][key][pos] = parseInt(val)
                    }
                }
            } else {
                for (let pos in dimensions[screensize][key]) {
                    dimensions[screensize][key][pos] = parseInt(val)
                }
            }

            setAttributes({dim: dimensions})
        }

        const setResponsive = enabled => {
            setAttributes({responsive: enabled, screensize: 'desktop'})
        }

        const setEqual = (key, val) => {
            const equals = {...equal}
            equal[screensize][key] = val
            setAttributes({equal: equals})

            if (val) {
                setDimensionsEqual(key, dim[screensize][key].t)
            }
        }

        const setBorderStyle = (style) => {
            const borders = {...border}

            if ( ! responsive) {
                for (let size in borders) {
                    borders[size].style = style
                }
            } else {
                borders[screensize].style = style
            }

            setAttributes({border: borders})
        }

        const setBorderColor = (color) => {
            const borders = {...border}

            if ( ! responsive) {
                for (let size in borders) {
                    borders[size].color = color
                }
            } else {
                borders[screensize].color = color
            }

            setAttributes({border: borders})
        }

        const updateMaxWidth = data => {
            setAttributes({maxWidth: {...maxWidth, ...data}})
        }

        const labels = {
            t: __('top', 'sfat-spacer'),
            r: __('right', 'sfat-spacer'),
            b: __('bottom', 'sfat-spacer'),
            l: __('left', 'sfat-spacer'),
        }

        return (
            <div {...blockProps}>
                <InspectorControls>
                    <PanelBody title={__('Spaces', 'sfat-spacer')}>
                        <CheckboxControl label={__('Responsive', 'sfat-spacer')}
                                         checked={responsive}
                                         onChange={enabled => setResponsive(enabled)}/>

                        {attributes.responsive &&
                            <ResponsiveButtonList
                                value={screensize}
                                onChange={screensize => setAttributes({screensize})}/>}

                        <SpaceVisualization dimensions={dim[screensize]}/>

                        {['margin', 'border', 'padding'].map(key =>
                            <Fragment>
                                <label>{__(key, 'sfat-spacer')}</label>
                                {equal[screensize][key] &&
                                    <PanelRow className={'sfat-input-fields equal'}>
                                        <TextControl type={'number'}
                                             value={dim[screensize][key].t}
                                             onChange={val => setDimensionsEqual(key, val)}/>
                                        <Button variant='tertiary' onClick={() => setEqual(key, false)}>
                                            <Dashicon icon='editor-unlink'/>
                                        </Button>
                                    </PanelRow>
                                ||
                                    <PanelRow className={'sfat-input-fields'}>
                                        {['t', 'r', 'b', 'l'].map(pos => {
                                            return maxWidth.enabled && maxWidth.centered && 'margin' === key && ['l', 'r'].includes(pos) &&
                                                <TextControl type={'text'}
                                                     label={labels[pos]}
                                                     value={'auto'}
                                                     disabled={true}/>
                                            ||
                                                <TextControl type={'number'}
                                                     label={labels[pos]}
                                                     value={dim[screensize][key][pos]}
                                                     onChange={val => setDimension(key, pos, val)}/>})}
                                        <Button variant='tertiary' onClick={() => setEqual(key, true)}>
                                            <Dashicon icon='admin-links'/>
                                        </Button>
                                    </PanelRow>}
                            </Fragment>)}

                        <label>{__('Border color', 'sfat-spacer')}</label>
                        <ColorPalette
                            value={border[screensize].color}
                            onChange={color => setBorderColor(color)}/>

                        <SelectControl
                            label={__('Border style', 'sfat-spacer')}
                            options={[
                                {value: 'solid', label: __('solid', 'sfat-spacer')},
                                {value: 'dashed', label: __('dashed', 'sfat-spacer')}
                            ]}
                            value={border[screensize].style}
                            onChange={style => setBorderStyle(style)}/>
                    </PanelBody>
                    <PanelBody title={__('Max. width', 'sfat-spacer')}>
                        <CheckboxControl
                            label={__('Activate', 'sfat-spacer')}
                            checked={maxWidth.enabled}
                            onChange={enabled => updateMaxWidth({enabled})}/>

                        {maxWidth.enabled &&
                            <Fragment>
                                <TextControl
                                    label={__('Max. width', 'sfat-spacer')}
                                    value={maxWidth.value}
                                    onChange={value => updateMaxWidth({value})}/>

                                <SelectControl
                                    label={__('Unit', 'sfat-spacer')}
                                    options={[
                                        {value: '%', label: '%'},
                                        {value: 'px', label: 'px'},
                                    ]}
                                    value={maxWidth.unit}
                                    onChange={unit => updateMaxWidth({unit})}/>

                                <CheckboxControl
                                    label={__('Center horizontal', 'sfat-spacer')}
                                    checked={maxWidth.centered}
                                    onChange={centered => updateMaxWidth({centered})}/>
                            </Fragment>}
                    </PanelBody>
                </InspectorControls>
                <InnerBlocks/>
            </div>
        )
    },

    save() {
        return InnerBlocks.Content()
    }
})
