import {PanelRow} from "@wordpress/components";
import React, {Fragment, useState} from "react";
import {__} from '@wordpress/i18n'

const Element = ({className, parentLabelSize, hoverElement, onInit, onHover, dimensions, label, children}) => {
    const hover = (active) => {
        onHover(className, active)
    }

    const onMouseOver = e => {
        e.preventDefault()
        e.stopPropagation()
        hover(true)
    }

    const onMouseOut = e => {
        e.preventDefault()
        e.stopPropagation()
        hover(false)
    }

    const displayValue = val => {
        if (val) {
            return `${val}px`
        }
        return '-'
    }

    const style = {}

    if (className !== 'margin' && parentLabelSize) {
        style.left = parentLabelSize.l + 'px'
        style.width = `calc(100% - ${Math.ceil(parentLabelSize.l + parentLabelSize.r)}px`
    }

    let labelWidthLeft = null
    let labelWidthRight = null

    if (className !== 'box') {
        const labelLeft = displayValue(dimensions.l)
        const labelRight = displayValue(dimensions.r)

        const expectedCharWidth = 4
        labelWidthLeft = 15 + labelLeft.length * expectedCharWidth
        labelWidthRight = 15 + labelRight.length * expectedCharWidth

        onInit(labelWidthLeft, labelWidthRight)
    }

    return (<div
        className={className}
        style={style}
        data-no-hover={hoverElement && hoverElement !== className ? 1 : 0}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}>
            {className !== 'box' &&
                <Fragment>
                    <span className="name">{label?.toLowerCase()}</span>
                    <span className="top">{displayValue(dimensions.t)}</span>
                    <span className="right" style={{width: labelWidthRight + 'px'}}>{displayValue(dimensions.r)}</span>
                    <span className="bottom">{displayValue(dimensions.b)}</span>
                    <span className="left" style={{width: labelWidthLeft + 'px'}}>{displayValue(dimensions.l)}</span>
                </Fragment>}
            {children}
    </div>)
}

export const SpaceVisualization = ({dimensions}) => {
    const [hoverElem, setHoverElem] = useState(null)

    const setHover = elem => setHoverElem(elem)
    const unsetHover = elem => hoverElem === elem && setHover(null)
    const updateHover = (key, hover) => hover ? setHover(key) : unsetHover(key)

    const labelSizes = []

    const setLabelSize = (pos, l, r) => {
        labelSizes[pos] = {l, r}
    }

    const Elem = ({className, parent, children, label}) => {
        let parentLabelSize = parent && 'undefined' !== typeof labelSizes[parent] ? labelSizes[parent] : 0

        return <Element
            className={className}
            label={label}
            parentLabelSize={parentLabelSize}
            hoverElement={hoverElem}
            dimensions={dimensions[className]}
            onInit={(l, r) => setLabelSize(className, l, r)}
            onHover={(className, hover) => updateHover(className, hover)}>
            {children}
        </Element>
    }

    return <PanelRow className={"space-visualization"}>
        <Elem className={'margin'} label={__('margin', 'sfat-spacer')}>
            <Elem className={'border'} parent={'margin'} label={__('border', 'sfat-spacer')}>
                <Elem className={'padding'} parent={'border'} label={__('padding', 'sfat-spacer')}>
                    <Elem className={'box'} parent={'padding'}/>
                </Elem>
            </Elem>
        </Elem>
    </PanelRow>
}
